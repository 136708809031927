@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

$primary: #b19687;
$secondary: #283146;
$tertiary: #524c50;
$lightBlack: #171c29;
$mediumGrey: #999;
$white: #fff;
$black: #000000;
$border: #d3d3d3;
$danger: #f16667;
$success: #6cd098;
$warning: #fdb927;

// export
:export {
  primary: $primary;
}

#root ~ iframe {
  display: none !important;
}

// mixin
// font size 8 to 36
$font-size-step: 1px;
@for $i from 8 through 36 {
  .fs-#{$i} {
    font-size: ($font-size-step * $i);
  }
}

// font weight
$font-weight-step: 100;
@for $i from 4 through 8 {
  .fw-#{$i * 100} {
    font-weight: $i * $font-weight-step;
  }
}

@mixin flexWrap(
  $align-items: center,
  $justify-content: flex-start,
  $flex-wrap: nowrap,
  $direction: row
) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-wrap: $flex-wrap;
  flex-direction: $direction;
}

@mixin padd(
  $paddingTop: 0,
  $paddingRight: 0,
  $paddingBottom: 0,
  $paddingLeft: 0
) {
  padding-top: $paddingTop;
  padding-right: $paddingRight;
  padding-bottom: $paddingBottom;
  padding-left: $paddingLeft;
}

@mixin ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $white 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

* {
  word-break: break-all;
}
body {
  letter-spacing: 0.3px;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: $primary;
  &:hover {
    color: $primary;
  }
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  color: $lightBlack;
  font-size: 14px;
}

.text- {
  &primary {
    color: $primary !important;
  }
  &danger {
    color: $danger;
  }
}

.cursorPointer {
  cursor: pointer;
}

// header
.projectHeader {
  background-color: $white;
  .container-fluid {
    padding-inline: 20px;
  }
  .navbar-brand {
    padding: 0;
    cursor: pointer;
    margin-right: 0;
    img {
      height: 35px;
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $mediumGrey;
        font-size: 16px;
        font-weight: 400;
        margin-inline: 10px;
        position: relative;
        cursor: pointer;
        &::after {
          background-color: initial;
          bottom: -11px;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          transition: 0.2s ease-in;
          width: 100%;
        }
        &:hover,
        &.active {
          color: $primary;
          &::after {
            background-color: $primary;
          }
        }
      }
      &.dropdown {
        .dropdown-menu {
          padding: 0;
          .dropdown-item {
            background-color: transparent;
            padding: 10px;
            &.active,
            &:focus,
            &:active:focus {
              color: $primary;
            }

            &:not(:last-child) {
              border-bottom: 1px dashed $border;
            }
          }
        }
      }
    }
  }
  .nav-link.active {
    font-weight: 500;
  }

  .logoutBtn {
    color: $mediumGrey;
  }
  .navbarRight {
    svg {
      width: 18px;
      height: 18px;
      stroke: $black;
    }
  }
}

.sidebarWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  z-index: 9;
  visibility: hidden;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba($color: $black, $alpha: 0.3);
    z-index: -1;
    display: none;
  }
  .sidebarInner {
    width: 260px;
    height: 100%;
    background-color: $white;
    transform: translateX(-260px);
    transition: 0.3s ease-in-out;
    .userInfo {
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px dashed $border;
      margin-bottom: 10px;
      cursor: pointer;
      .userAvatar {
        width: 45px;
        height: 45px;
        border: 1px solid $border;
        padding: 5px;
        border-radius: 50px;
        margin-right: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .userName {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 16px;
        color: $mediumGrey;
        span {
          display: block;
        }
        i {
          margin-left: 5px !important;
        }
      }
    }
    .sidebarMenu {
      overflow-y: auto;
      height: 100%;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary; /* color of the scroll thumb */
        border-radius: 500px;
        width: 4px; /* roundness of the scroll thumb */
      }
      .list-group {
        padding-inline: 10px;
        padding-bottom: 100px;
        .list-group-item {
          background-color: transparent !important;
          color: $mediumGrey;
          position: relative;
          border: none;
          font-size: 14px;
          padding: 10px 8px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -4px;
            width: 2px;
            height: 100%;
            background-color: transparent;
          }
          &.active {
            color: $primary;
            &::before {
              background-color: $primary;
            }
          }
        }
      }
    }
  }
  &.show {
    visibility: visible;
    .sidebarInner {
      transform: translateX(0);
    }
    &::before {
      display: block;
    }
  }
}

.toggleIcon {
  background: transparent;
  position: relative;
  padding: 0;
  width: 40px;
  height: 36px;
  border-radius: 4px;
  border: none;
  display: none;
  span {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 2px;
    background: #222;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: -8px;
      width: 24px;
      height: 2px;
      background: #222;
    }
    &::after {
      bottom: -8px;
      top: auto;
    }
  }
}

// badge
.badge {
  font-weight: 500;
  &.outline {
    background-color: #dc35454a !important;
    border: 1px solid #dc354521;
    color: #dc3545;
  }
}

// footer
.projectFooter {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  color: $black;
  font-weight: 300;
  p {
    margin: 0;
  }
  .copyright {
    display: flex;
    align-items: flex-end;
  }

  a {
    margin-left: 15px;
    color: $mediumGrey;
    text-decoration: none;
  }
  .lsLink {
    color: #31c5c7;
    font-weight: 400;
  }
}

// button
.btn {
  height: 44px;
  font-size: 14px;
  padding-inline: 20px;
  border-radius: 8px;
  &:focus,
  &:active:focus {
    box-shadow: none !important;
  }
  &.btn-submit {
    width: calc(min(100% - 1rem, 280px));
    display: block;
    margin: 25px auto 0;
  }
  &-primary {
    background-color: $primary;
    border-color: $primary;
    &:hover,
    &:focus,
    &:active:focus {
      border-color: rgba($color: $primary, $alpha: 0.9);
      background-color: rgba($color: $primary, $alpha: 0.9);
    }

    &:disabled {
      border-color: rgba($color: $primary, $alpha: 0.8);
      background-color: rgba($color: $primary, $alpha: 0.8);
    }
  }
  &-outline-primary {
    border-color: $primary;
    color: $primary;
    &:hover,
    &:focus {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }
    &:active:focus {
      background-color: transparent;
      border-color: $primary;
      color: $primary;
    }
    &:disabled {
      border-color: rgba($color: $primary, $alpha: 0.8);
      color: rgba($color: $primary, $alpha: 0.8);
    }
  }
  &-link {
    padding: 0 10px;
    text-decoration: none;
    height: auto;
    color: $primary;
    &:hover {
      color: $primary;
    }
    &.remove {
      color: $danger;
      svg {
        stroke: $danger;
      }
    }
  }
  &.btnRemove,
  &.btnAdd {
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

// form
input {
  //remove number arrows
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  // auto login field
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
}
label {
  font-size: 12px;
  color: $black;
  margin-bottom: 6px !important;
}
input[type="file"] {
  display: none;
}
.form-control,
.form-select {
  height: 45px;
  font-size: 14px;
  color: $lightBlack;
  border-radius: 4px;
  border-color: $border;
  box-shadow: none;
  &:focus {
    box-shadow: none;
    border-color: $primary;
  }
  &.h-auto {
    height: auto;
  }
}
textarea {
  &.form-control {
    height: auto;
  }
}
.input-group {
  border: 1px solid $border;
  border-radius: 4px;
  margin-bottom: 6px;
  transition: border 0.3s ease-in-out;
  &:focus-within {
    border-color: $primary;
  }
  .input-group-text {
    background-color: $white;
    border: none;
    color: $black;
    height: auto;
    font-size: 16px;
    padding-right: 0;
  }
  input {
    border-radius: 0px;
    border-color: transparent !important;
    padding-left: 10px;
    ~ .input-group-text {
      padding-right: 15px;
    }
  }
}
.form-check {
  .form-check-input {
    border-color: $mediumGrey;
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

input[type="radio"],
input[type="checkbox"] {
  &:checked {
    background-color: $primary;
    border-color: $primary;
  }
  box-shadow: none !important;
}

// message variable
.messageVeriabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  label {
    margin-bottom: 0 !important;
  }
  .dropdown {
    margin-bottom: 6px;
    .dropdown-toggle {
      background-color: $primary;
      border-color: $primary;
      height: auto;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 500;
      box-shadow: none !important;
    }
    .dropdown-menu {
      padding: 0 !important;
      border: none;
      box-shadow: 0 0 10px 0 rgba($color: $mediumGrey, $alpha: 0.2);
      max-height: 200px;
      overflow-y: auto;
      .dropdown-item {
        text-align: right;
        font-size: 16px;
        padding: 10px 8px;
        color: $lightBlack;
        border-bottom: 1px dashed rgba($color: $mediumGrey, $alpha: 0.4);
        background-color: transparent;
        &:hover {
          color: $primary;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

// react quil/ rich editor
.quill {
  background-color: $white;
  .ql-toolbar {
    border-color: $border !important;
  }
  .ql-container {
    border-color: $border !important;
    min-height: 100px;
  }
}

.rdtPicker {
  .dow {
    white-space: nowrap;
  }
}

// error
.form-error {
  font-size: 12px;
  color: $danger;
}

// upload
.uploadButton {
  border: 1px solid $primary;
  background-color: $primary;
  padding: 0 30px;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  max-width: 200px;
  width: 100%;
  color: $white;
  @include flexWrap(center, center, wrap);
}

// upload Preview
.uploadPreview {
  width: 80px;
  height: 80px;
  border: 1px solid $border;
  border-radius: 4px;
  @include flexWrap(center, center, wrap);
  position: relative;
  margin-top: 20px;
  svg {
    width: 50px;
    height: 50px;
    stroke-width: 1px;
  }
  .remove {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      border: 1px solid $danger;
      border-radius: 50%;
      stroke: red;
      background-color: $white;
    }
  }
}

.uploadSection {
  @include flexWrap(items, content, nowrap);
  .uploadItem {
    border: 1px solid $border;
    cursor: pointer;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    @include flexWrap(center, center, wrap);
    position: relative;
    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
    }
    .deleteIcon {
      width: 18px;
      cursor: pointer;
      height: 18px;
      position: absolute;
      top: -5px;
      right: -5px;
      @include flexWrap(center, center, wrap);
      background-color: $danger;
      color: $white;
      border-radius: 56px;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
  .uploadBody {
    @include flexWrap(center, flex-start, wrap);
  }
}

// react select

.react-select-container {
  .react-select__control {
    min-height: 45px;
    min-width: 200px;
  }

  .react-select__value-container {
    .react-select__placeholder {
      font-size: 14px;
      color: #171c29;
    }
  }
  &.withRelative {
    .react-select__menu {
      position: relative;
      .css-1n6sfyn-MenuList {
        max-height: 150px !important;
      }
    }
  }
}

.notesCard {
  margin-top: 15px;
  .card-header {
    .card-title {
      font-size: 14px !important;
    }
  }
  .card-body {
    p {
      margin-bottom: 0;
    }
  }
}

// .react-select {
//   height: 45px;
//   .css-1s2u09g-control {
//     border-color: $border;
//     &:hover {
//       border-color: $border;
//     }
//   }
//   .css-1pahdxg-control {
//     min-height: 45px;
//     border-color: $primary !important;
//     box-shadow: none;
//   }
// }

// .react-select__control {
//   min-height: 45px;
//   font-size: 14px;
// }

// .react-select__placeholder {
//   color: #171c29;
// }

// .react-select__menu.css-1nmdiq5-menu {
//   position: relative;
//   .css-1n6sfyn-MenuList {
//     max-height: 150px !important;
//   }
// }

// pagination
.tableCount {
  font-size: 14px;
  color: $mediumGrey;
}

.noData {
  text-align: center;
  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.paginationWrap {
  @include flexWrap(center, space-between, wrap);

  .showng_entries {
    font-size: 12px;
  }
  .paginationWithCount {
    @include flexWrap(center, flex-end, wrap);
    span {
      font-size: 12px;
    }
    .form-select {
      height: auto !important;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 6px;
      padding-right: 32px;
      font-size: 12px;
    }
  }

  .showng_entries {
    span {
      font-weight: 600;
    }
  }
}

.pagination {
  align-items: center;
  li {
    a {
      min-width: 28px !important;
      min-height: 28px !important;
      @include flexWrap(center, center, wrap);
      margin: 0 5px !important;
      padding: 0 !important;
      background-color: $white;
      border-radius: 50px !important;
      border: 1px solid $border;
      font-size: 12px;
      i {
        font-size: 10px;
        color: $black;
      }
      &:hover:not([aria-disabled="true"]) {
        background-color: $primary;
        color: $white !important;
        border-color: $primary;
        i {
          color: $white !important;
        }
      }
    }
    &.active {
      a {
        border: 1px solid $primary;
        background-color: $primary;
        color: $white !important;
      }
    }
    &:first-child,
    &:last-child {
      a {
        font-size: 22px;
      }
    }
    &.disabled {
      pointer-events: none;
    }
  }
}

// login
.authWrapper {
  position: relative;
  top: 0;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  background-color: #f8f8f8 !important;
  .loginWrap {
    width: calc(min(100% - 2rem, 450px));
    min-height: calc(100vh - 50px);
    padding: 30px 0;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    .companyLogo {
      margin-inline: auto;
      display: block;
      max-width: 250px;
    }
    h2 {
      text-align: center;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 26px;
      text-transform: uppercase;
    }
    form {
      background-color: $white;
      padding: 40px 25px;
      border: 1px solid hsla(0, 0%, 89.4%, 0.5);
      border-radius: 8px;
      margin-top: 40px;
      width: 100%;
    }
  }
  .authFooter {
    padding: 15px 30px;
    width: 100%;
    .container-fluid {
      display: flex;
      justify-content: space-between;
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;
        li {
          font-size: 13px;
          color: #999;
        }
      }
      .copyright {
        font-size: 13px;
        color: #999;
        a {
          color: #31c5c7;
          padding-left: 10px;
        }
      }
    }
  }
  .forgotPassword {
    color: $mediumGrey;
    font-size: 14px;
    &:hover {
      color: $primary;
    }
  }
}

// innerwrapper
.mainWrapper {
  background-color: #f8f8f8 !important;
  width: 100%;
  min-height: 100vh;
  .innerWrapper {
    min-height: calc(100vh - 108px);
    padding: 20px;
  }
}

// inner header
.innerHeader {
  @include flexWrap(center, space-between, wrap);
  margin-bottom: 30px;

  .backToPage {
    @include flexWrap(center, flex-start, wrap);
    .btn {
      padding-left: 0;
      color: $black;
      &:hover {
        color: $primary;
      }
    }
  }
  h2 {
    margin: 0;
    font-size: 16px;
  }
  .hideShowFilter {
    display: none;
  }
  .right {
    display: flex;
    align-items: center;
    .btn {
      height: auto;
      white-space: nowrap;
      &-link {
        padding-right: 0;
      }
      + .btn {
        margin-left: 10px;
        padding-right: 0;
        padding-left: 0;
      }
    }
    .form-select {
      height: auto;
    }
  }
}

// spiner
.spinner-border {
  width: 16px;
  height: 16px;
  border-width: 2px;
}

// filter
.filterWrapper {
  background-color: $white;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid rgba($border, 0.6);
  .filterIcon {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-right: 1px solid rgba($color: $border, $alpha: 0.8);
    margin-right: 15px;
    padding-right: 15px;
  }
  .filterForm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .formGroup {
      margin-right: 14px;
      margin-top: 7px;
      margin-bottom: 7px;
      flex: 0 1 150px;
      // &.searchbar {
      //   max-width: 200px;
      // }
      &.multiSelect {
        width: 200px;
      }
    }
  }
  .input-group {
    margin-bottom: 0;
  }
}

.customDateRange {
  display: flex;
  align-items: center;
}

// daterange
.DateRangePicker {
  width: 100%;
  height: 45px;
  > div {
    width: 100%;
    height: 100%;
  }
  .DateRangePickerInput {
    border-radius: 4px;
    border-color: $border;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .DateInput {
      height: 100%;
      border-radius: 8px;
      flex: 1;
      input {
        height: 100%;
        font-size: 14px;
        border-radius: 8px;
        color: $mediumGrey;
        position: relative;
        font-weight: 400;
        &.DateInput_input__focused {
          border-color: $primary;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}

.dateRangeWithDropDown {
  @include flexWrap(center, center, nowrap);
  .form-select {
    width: 150px;
  }
  .DateRangePicker {
    margin-left: 15px;
  }
}

.DayPicker_transitionContainer {
  height: 350px !important;
}

.bg-light {
  td {
    background-color: #f5f5f5 !important;
  }
}

// table
table {
  tr {
    td,
    th {
      vertical-align: middle;
      font-size: 12px;
      padding: 10px 8px !important;
      // text-transform: uppercase;
      .checkColumn {
        display: flex;
        .form-check {
          // margin-right: 10px;
          margin-bottom: 0;
        }
      }
    }
    td {
      &.tableLink,
      .tableLink {
        color: $primary;
        cursor: pointer;
      }
      .tableUserInfo {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
          border-radius: 50px;
          margin-right: 10px;
          border: 1px solid $border;
        }
        .userContact {
          h2 {
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
          }
          .userAction {
            display: flex;
            align-items: center;
            li {
              cursor: pointer;
              padding-right: 15px;
              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
  thead {
    tr {
      th {
        border: none;
        font-weight: 500;
        white-space: nowrap;
        background-color: rgba($mediumGrey, 0.2) !important;
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
  > :not(:first-child) {
    border: none !important;
  }
  tbody {
    tr {
      td {
        border-bottom-color: rgba($border, 0.8);
        color: rgba($black, 0.8) !important;
        font-weight: 400;
        .btn {
          height: auto;
          &:not(:first-child) {
            margin-left: 10px;
          }
          
          i {
            font-size: 14px;
          }
          &-link {
            padding: 0;
          }
        }
        .action {
          svg {
            width: 16px;
            height: 16px;
          }
          .edit {
            color: $mediumGrey;
          }
          .delete {
            color: $danger !important;
          }
        }
        .form-select,
        .form-control {
          height: auto;
          width: 150px;
          font-size: 12px;
        }
      }
    }
  }
}

.estimateCard {
  // align-items: stretch
  @include flexWrap(stretch, flex-start, wrap);
  gap: 30px;
  li {
    flex: 1 0 400px;
    border: 1px solid #f5f5f5;
    background-color: rgb(237, 243, 255);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    @include flexWrap(center, flex-start, wrap);
    flex-direction: column;
    h3 {
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    span {
      display: block;
      margin-top: 5px;
    }
  }
}

// main content
.main_content_wrapper {
  background-color: $white;
  padding: 15px;
  border-radius: 8px;
}

// tabs
.nav {
  &.nav-pills {
    position: relative;
    z-index: 1;
    .nav-item {
      .nav-link {
        cursor: pointer;
        background-color: transparent;
        color: $mediumGrey;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        &.active {
          color: $primary;
          border-bottom-color: $primary;
        }
      }
    }
    &:after {
      border-bottom: 1px solid $border;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      z-index: -1;
    }
  }
}
.tab-content {
  padding: 10px;
}

// card
.card {
  background-color: $white;
  border-color: rgba($border, 0.3);
  box-shadow: 0 0 10px 0 rgba($color: $mediumGrey, $alpha: 0.02);
  border-radius: 8px;
  .card-header,
  .card-footer {
    background-color: transparent;
    border-color: rgba($color: $mediumGrey, $alpha: 0.2);
  }
  .card-header {
    padding: 10px 15px;
    @include flexWrap(center, space-between, wrap);
    .card-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  &.cardDesign {
    margin-bottom: 20px;
  }
  &.payrollList {
    margin-bottom: 20px;
    .card-body {
      // h2 {
      //   font-size: 20px;
      //   font-weight: 600;
      // }
      ul {
        @include flexWrap(center, space-between, wrap);
        gap: 15px;
        li {
          flex: 1 0 48%;
          border: 0;
          font-size: 14px;
          padding-bottom: 15px;
          
          flex-grow: 0;
          margin: 0;
          &.break {
            border: none;
          }
          &.fullWidth {
            flex-basis: 100%;
            border: none;
            > div {
              width: 48%;
            }
          }
          > span {
            display: block;
            &:first-child {
              margin-bottom: 5px;
              font-weight: 500;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px dashed $border;
          }
        }
      }
    }
  }
}

.learningCard {
  width: 100%;
  height: 200px;
  position: relative;
  padding: 0px;
  background-color: $white;
  border: 1px solid rgba($black, 0.08);
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: radial-gradient(
      circle,
      #000000,
      #0b0a0a,
      #131213,
      #191819,
      #1e1e1f,
      #1e1e1f,
      #1e1e1f,
      #1e1e1f,
      #191819,
      #131213,
      #0b0a0a,
      #000000
    );
    opacity: 0.8;
  }

  img {
    width: 100%;
    height: 100%;
    // aspect-ratio: 3/2;
    object-fit: contain;
  }
  .learningContent {
    padding: 15px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      color: $white;
      font-size: 20px;
    }
    p {
      color: $white;
    }
  }
}

// quesAnsWrap
.quesAnsWrap {
  h5 {
    font-size: 16px;
  }
}

// modal
.modal {
  .closeBtn {
    color: $mediumGrey;
    margin-right: 10px !important;
  }
  .modal-header {
    padding: 0.9rem 1rem 0.75rem;
    .modal-title {
      font-size: 16px;
    }
  }
  .modal-footer {
    .btn {
      margin: 0;
      height: 40px;
      padding: 0 20px;
      line-height: 38px;

      + .btn {
        margin-left: 15px;
      }
    }
  }
}

.payRollModal {
  .modal-header {
    h5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }
  }
}

.traningContent {
  .traningImageWrap {
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
    // max-height: 300px;widdt
    max-width: 450px;
    margin-bottom: 20px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .topicVideo {
    // height: 350px;
    max-width: 550px;
    width: auto;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    position: relative;
    video {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }
    iframe {
      display: block;
      margin: 25px auto;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      width: 100%;
      height: 100%;
      max-height: unset;
    }
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    margin-bottom: 5px;
    color: rgba($black, 0.8);
  }
}

// upload progress css
#mediaQueueViewerWrapper {
  position: fixed;
  right: 25px;
  bottom: 60px;
  z-index: 999;
  height: 50px;
  background-color: $primary;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  #uploadeProgressHeader {
    color: $white;
    font-size: 14px;
  }
}

// full page loader
.fullPgLoaderWrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 9999;
  opacity: 0.95;
  background: $white;

  .loaderWrap {
    width: 250px;
    height: 200px;
    text-align: center;

    div {
      justify-content: center;
    }

    p {
      font-size: 16px;
      margin-top: 10px;
      color: $mediumGrey;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
.floatingBtn {
  position: fixed;
  bottom: 55px;
  right: 10px;
}
.infoWrap {
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  ul {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    column-count: 2;
    column-width: 200px; /* Adjust the width of each column */
    column-gap: 20px;
    li {
      @include padd(0, 10px, 10px, 0);
      // flex: 1 0 300px;
      margin-bottom: 10px;
      border-bottom: 1px dashed $border;
      font-size: 14px;
      break-inside: avoid;
      span {
        margin-bottom: 5px;
        display: block;
        font-weight: 500;
        .badge {
          display: inline-block;
          height: 20px;
          line-height: 19px;
          padding: 0 5px;
        }
      }

      svg {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        // stroke: rgba($color: $black, $alpha: 0.4);
      }
      button {
        padding-right: 0;
      }
    }
  }
}

.popover {
  .popover-body {
    button {
      padding: 0;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
}

.progress {
  height: 8px;
}
.bg- {
  &success {
    background-color: $success !important;
  }
  &primary {
    background-color: $primary !important;
  }
}

// status Update
.statusList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;

  li {
    flex: 1 0 auto;
    position: relative;
    margin: auto;

    span {
      display: block;
      font-size: 12px;
      z-index: 2;
      width: 120px;

      &.status {
        position: absolute;
        color: rgba($black, 0.3);
        top: 0;
      }

      &.count {
        width: 30px;
        height: 30px;
        background-color: rgba($black, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        font-size: 16px;
        color: $white;
        font-weight: 600;
        z-index: 2;
        margin: 50px 0;
        position: relative;
      }

      &.date {
        position: absolute;
        bottom: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background-color: $border;
      z-index: 1;
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    &.active {
      span {
        &.status {
          color: $lightBlack;
        }

        &.count {
          background-color: $primary;
        }
      }
    }

    &.previousActive {
      span {
        &.count {
          background-color: #fbc658;
        }
      }
    }
  }
}

.comma-separated {
  text-decoration: none !important;

  &::after {
    content: ", ";
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
}

// custom video player
.customVideoWrapper {
  position: relative;
  overflow: hidden;

  video {
    background-color: $black;
  }

  .videoControlsWrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 9px 10px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(100%);
    transition: transform 0.25s linear;
    background: linear-gradient(to top, rgba($black, 0.25), rgba($black, 0.02));
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .videoSeekBarWrap {
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    position: relative;
  }

  .videoSeekBar {
    background: $mediumGrey;
    cursor: pointer;
    width: 100%;
    height: 4px;
    border-radius: 20px;
  }

  .dragHandle {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    padding: 0;
    background: transparent;
    border: 0 !important;
    height: unset;
    position: absolute;
    top: -5px;
    left: 0;

    &::-webkit-slider-thumb {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 1px 6px rgba($black, 0.2);
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
    }

    &::-moz-range-thumb {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 1px 6px rgba($black, 0.2);
      cursor: pointer;
    }
  }

  .videoProgress {
    background: $primary;
    height: 100%;
    transition: all 0.3s;
    border-radius: 20px;
  }

  .videoControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    > div {
      display: flex;
      align-items: center;
    }

    .btn {
      width: 33px;
      height: 33px;
      padding: 0;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 29px;
      text-decoration: none !important;
      margin: 0 5px;
      color: $white !important;
      @include ripple;
      border-radius: 6px;
      svg {
        width: 24px;
        height: 24px;
        fill: $white;
        stroke: $white;
      }

      img {
        height: 32px;
        width: unset;
        object-fit: contain;
      }
    }
  }

  .videoTimestamp {
    font-size: 14px;
    color: $white;
    margin-left: 5px;
    font-weight: 600;
    margin-top: 3px;
  }

  .videoVolume {
    display: flex;
    align-items: center;
    position: relative;

    .dragHandle {
      width: 70px;
      top: 10px;
      right: 43px;
      left: unset;
      border-radius: 20px;
    }

    .videoSeekBar {
      width: 70px;
      display: none;
      transition: all 0.3s;
    }

    &:hover .videoSeekBar {
      display: block;
      transition: all 0.3s;
    }
  }

  &.nowPlaying {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: #111;
    font-weight: 500;
    font-size: 16px;
    border-radius: 16px;
    min-height: 300px;
  }

  .closeVideo,
  .enlargeVideo,
  .dragVideo {
    position: absolute;
    right: 7px;
    top: 5px;
    transform: translateY(-150%);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    width: 29px;
    height: 29px;
    text-align: center;
    padding: 0;
    text-decoration: none;
    border-radius: 50%;
    background-color: rgba($black, 0.4);
    transition: transform 0.25s linear;
  }

  .enlargeVideo {
    right: unset;
    left: 7px;

    img {
      height: 18px;
      width: unset;
      object-fit: contain;
    }
  }

  .dragVideo {
    cursor: grab !important;
    right: unset;
    left: 50px;
    font-size: 20px;

    &:active {
      cursor: grabbing !important;
    }
  }

  .playPauseVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;
    text-decoration: none;
    transition: all 0.3s;

    img {
      height: 42px;
      object-fit: contain;
      width: unset;
    }
    svg {
      width: 50px;
      height: 50px;
      fill: $white;
      stroke: $white;
      filter: drop-shadow(0px 2px 4px rgba($black, 0.4));
    }
  }

  .playingVideo {
    display: none;
    transition: all 0.3s;
  }

  .videoSettings {
    position: absolute;
    bottom: 53px;
    background: $white;
    border-radius: 6px;
    right: 15px;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
  }

  &.fullscreen {
    position: fixed;
    inset: 0;
    z-index: 1031;
    max-height: unset;

    video {
      border-radius: 0;
      width: 100%;
      height: 100%;
      max-height: unset;
    }

    .videoControlsWrap {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .videoControls .btn {
      margin: 0 7px !important;
    }

    .videoVolume {
      .dragHandle {
        right: 47px;
      }
    }
  }

  &.fullscreen.landscapeVideoWrap,
  &.fullscreen.portraitVideoWrap,
  &.fullscreen.squareVideoWrap,
  &.floating.landscapeVideoWrap,
  &.floating.portraitVideoWrap,
  &.floating.squareVideoWrap {
    padding-top: 0;
  }

  .theatre {
    border-radius: 0;
  }

  &.fullscreen.landscapeVideoWrap {
    video {
      object-fit: contain;
    }
  }

  &.floating {
    position: fixed;
    z-index: 100;
    bottom: 15px;
    right: 15px;
    width: 350px !important;
    height: 196.875px !important;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);

    video {
      height: 100%;
      display: block;
      border-radius: 16px;
    }

    .videoTimestamp {
      display: none;
    }

    &:not(.react-draggable-dragging) {
      -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
      transition: transform 0.5s ease-out;
    }
  }

  &:hover {
    .videoControlsWrap {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }

    .closeVideo,
    .enlargeVideo,
    .dragVideo {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }

    .playingVideo {
      display: flex;
      transition: all 0.3s;
    }
  }

  &:focus {
    .videoControlsWrap {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }
  }
}
// custom video player

// add topic
.topicUploadWrapper {
  border: 1px solid $border;
  padding: 10px;
  border-radius: 8px;
}
.topicUploadWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .uploadWrap {
    flex: 1 0 200px;
    flex-grow: 1;
    cursor: pointer;
    padding: 30px 15px;
    border: 1px dashed $border;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    svg {
      width: 40px;
      height: 40px;
    }
    span {
      margin-top: 10px;
      display: block;
      font-size: 12px;
      color: $mediumGrey;
    }
  }
}

.uploadBody {
  display: flex;
  justify-content: flex-start;
  gap: 15px;

  h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .UploadContent {
    margin-bottom: 15px;
    overflow: auto;
    position: relative;
    padding-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .uploadItem {
      width: 200px;
      height: 200px;
      border: 1px dashed $border;
      padding: 2px;
      border-radius: 2px;
      position: relative;
      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
      .deleteIcon {
        cursor: pointer;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 20px;
        height: 20px;
        background-color: $danger;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        svg {
          width: 14px;
          height: 14px;
          stroke: $white;
        }
      }
    }
  }
}

// owners page
.ownerCard {
  .ownerImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid $border;
    overflow: hidden;
    margin: auto;
    img {
      aspect-ratio: 1/1;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .ownerInfo {
    margin-top: 15px;
    @include flexWrap(center, center, wrap);
    svg {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    padding-top: 15px;
    li {
      @include flexWrap(center, space-between, wrap);
      font-size: 14px;
      padding-top: 12px;
      padding-bottom: 12px;
      &:not(:last-child) {
        border-bottom: 1px dashed $border;
      }
      span {
        font-weight: 600;
      }
    }
  }
  .card-footer {
    button {
      display: block;
      padding: 0;
      width: 100%;
      height: 30px;
    }
  }
}

.userProfile {
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
  position: relative;
  .profilePic {
    border: 1px solid rgba($border, 0.4);
    border-radius: 50%;
    overflow: hidden;
  }
  .editIcon {
    position: absolute;
    right: 0;
    bottom: 10px;
    width: 25px;
    height: 25px;
    border: 1px solid $primary;
    background-color: $white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 14px;
      height: 14px;
      stroke: $primary;
    }
  }
}

.paymentAccordion {
  .accordion-item {
    &.accordionDisabled {
      .accordion-header {
        button {
          pointer-events: none;
          opacity: 0.7;
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .accordion-header {
    .accordion-button {
      flex-wrap: wrap;
      padding-inline: 0;
      &:focus {
        box-shadow: none;
        border: none;
      }
      .dateWrap {
        border: 1px solid $border;
        width: 50px;
        text-align: center;
        padding: 4px;
        margin-right: 10px;
        span {
          font-weight: 600;
          font-size: 12px;
          display: block;
        }
      }
      .accordionTitle {
        h6 {
          padding-bottom: 0;
          margin-bottom: 0;
          font-size: 14px;
        }
        span {
          font-weight: 400;
          font-size: 12px;
        }
      }
      &:not(.collapsed) {
        background-color: rgba($black, 0.05);
      }
    }
  }
  .accordion-body {
    ul {
      li {
        padding-top: 10px;
        font-size: 13px;
        &:not(:last-child) {
          border-bottom: 1px dashed $border;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.perioadWrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  .formGroup {
    margin-right: 15px;
  }
}

.summaryCard {
  margin-bottom: 30px;
  .card-header {
    .card-title {
      font-weight: 600;
    }
  }
  .card-body {
    h3 {
      font-size: 18px;
      font-weight: 600;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        flex: 1 0 49%;
        flex-shrink: 0;
        flex-grow: 0;
        padding-top: 10px;
        border-bottom: 1px dashed $border;
        padding-bottom: 10px;

        span {
          font-size: 14px;
          color: rgba($black, 0.6);
          &:first-child {
            font-weight: 500;
            margin-right: 10px;
            color: $black;
          }
        }
      }
    }
  }
}

.leaderBoardSettingWrap {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  margin: 5px 0;

  label {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0 !important;
  }
}

// leader board table css
.leaderBoardTable {
  .table {
    border-collapse: separate;
    border-spacing: 0 15px;

    thead {
      th {
        padding: 18px !important;
        color: $white;
        background: transparent;

        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
  
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      tr {
        background: linear-gradient(162deg, #2D3648F2 0%, #030812F2 100%);
      }
    }

    tbody {
      tr {
        background-color: $white;

        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
          td {
            color: $white !important;
            border-bottom: 0;
          }
        }

        &:nth-child(1) {
          background: linear-gradient(114.01deg, #875635 24.08%, #CB9064 44.57%, #B37951 93.69%);

          td:first-child:before {
            content: '1. ';
          }
        }
  
        &:nth-child(2) {
          background: linear-gradient(to right, #44a08d 0%, #093637 100%);
          
          td:first-child:before {
            content: '2. ';
          }
        }
  
        &:nth-child(3) {
          background: linear-gradient(to right, #09203f 0%, #537895 100%);
          
          td:first-child:before {
            content: '3. ';
          }
        }
  
        &:nth-child(4) {
          background: linear-gradient(to right,#be5869 0%, #403a3e 100%);

          td:first-child:before {
            content: '4. ';
          }
        }
  
        &:nth-child(5) {
          background: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);

          td:first-child:before {
            content: '5. ';
          }
        }
  
        td {
          padding: 18px !important;
          background-color: transparent;
  
          &:first-child {
            border-top-left-radius: 35px;
            border-bottom-left-radius: 35px;
          }
    
          &:last-child {
            border-top-right-radius: 35px;
            border-bottom-right-radius: 35px;
          }
        }
      }
    }
  }
}

.customTabs {
  padding-bottom: 3px;

  .nav-item {
    &:not(:first-child) {
      margin-left: 10px;
    }

    &:has(.nav-link.active) {
      position: relative;
  
      &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 2px;
        background: linear-gradient(165deg, #CD9C75, #875635);

        -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
        -webkit-mask: -webkit-linear-gradient(#fff 0 0) content-box, -webkit-linear-gradient(#fff 0 0);

        mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
        mask: -webkit-linear-gradient(#fff 0 0) content-box, -webkit-linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: 10;
      }
    } 
  }

  .nav-link {
    padding: 0 18px;
    font-size: 16px;
    height: 40px;
    line-height: 40px;

    &.active {
      background: linear-gradient(162.32deg, #2D3648 4.44%, #030812 98.89%);
      -webkit-font-smoothing: antialiased;
      border-radius: 6px !important;
    }
  }

  &+.tab-content {
    padding: 0;
  }
}

.affiliateAmount {
  flex: 1 1 auto;
  text-align: right;
  margin-bottom: 5px;
  span {
    font-size: 12px;
    display: block;
  }
}

.webinarSettings {
  li {
    border: 0;
    border-radius: 0 !important;

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}

// note: this project uses reactstrap version -> v9.
// media styles

@media all and (max-width: 991.98px) {
  .innerHeader {
    .hideShowFilter {
      display: block;
    }
  }
  .filterWrapper {
    .filterIcon {
      display: none;
    }
    display: none;
    &.show {
      display: block;
    }
    .filterForm {
      justify-content: space-between;
      .formGroup {
        flex: 1 0 200px !important;
      }
    }
  }
  .statusList {
    display: inherit;
    align-items: unset;
    overflow-x: visible;

    li {
      width: 270px;
      display: flex;
      align-items: center;

      span {
        width: 120px;
        position: relative !important;

        &.status {
          padding-right: 15px;
          text-align: right;
        }

        &.date {
          padding-left: 15px;
        }
      }

      &::before {
        left: 50%;
        top: 80px;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
      }
    }
  }
}

@media all and (max-width: 767.98px) {
  .toggleIcon {
    display: block;
  }
  .filterWrapper {
    .filterForm {
      justify-content: space-between;
      .formGroup {
        flex: 1 0 47% !important;
      }
    }
  }
}

@media all and (max-width: 578.98px) {
  .mainWrapper .innerWrapper {
    padding: 20px 15px;
  }

  .authWrapper {
    .authFooter {
      justify-content: center;
      text-align: center;
    }
    .copyright {
      width: 100%;
      justify-content: center;
      margin-top: 5px;
    }
  }
  .filterWrapper {
    .filterForm {
      .formGroup {
        margin-right: 0;
        flex: 1 0 100% !important;
      }
    }
  }

  // pagination
  .paginationWrap {
    .showng_entries {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
    .paginationWithCount {
      width: 100%;
      justify-content: center;
    }
    .pagination {
      width: 100%;
      margin-top: 10px;
      justify-content: center !important;
    }
  }

  .leaderBoardTable {
    .table {
      thead {
        th {
          padding: 15px !important;
        }
      }
  
      tbody {
        tr {
          td {
            padding: 15px !important;
          }
        }
      }
    }
  }

  .affiliateAmount {
    text-align: center;
    margin-top: 10px;
  }

}
